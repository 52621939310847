<template>
  <div class="warp">
    <el-form inline size="mini">
      <el-form-item label="Region ID:">
        <el-input v-model="extraData.id" type="number" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="Region Name:">
        <input-autocomplete
          :extraData.sync="extraData.name"
          search-key="STD_SEARCH_REGION"
          value-key="name"
          label-key="id"
          query-key="name"
          :disabled="extraData.id ? true : false"
          :queryOrderParams="{ page: 1, size: 500, deleteFlag: 1 }"
        />
      </el-form-item>
      <el-form-item label="Validity" >
        <el-select v-model="extraData.deleteFlag" placeholder="" clearable="">
          <el-option label="Yes" :value="1"></el-option>
          <el-option label="No" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >Search</el-button
        >
        <el-button @click="dialogAdd">Add</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      stripe
      v-loading="loading"
      @sort-change="sort"
      @expand-change="expandChange"
      :max-height="tableHeight"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
            class="expand-table"
            v-loading="expandData[props.row.id].loading"
            :data="expandData[props.row.id].data"
            style="width: 100%"
          >
            <el-table-column
              label="Source Data"
              width="140"
              align="center"
              prop="dataSourceValue"
            >
            </el-table-column>
            <el-table-column
              label="Source ID"
              width="140"
              align="center"
              prop="sourceRegionId"
            ></el-table-column>
            <el-table-column
              label="Region Name"
              width="140"
              align="center"
              prop="name"
            >
            </el-table-column>
            <el-table-column
              label="Remove"
              width="160"
              align="center"
            >
              <template v-slot="{ row }">
                <status-icon
                  @click.native="remove(row, props.row)"
                  :status="'delete'"
                />
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        label="Region ID"
        width="130"
        align="center"
        prop="id"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        label="Flag"
        align="center"
        min-width="100"
      >
      <template v-slot="{row}">
        <img v-if="row.logoUrl" class="flagImg" :src="row.logoUrl" alt="">
      </template>
      </el-table-column>
      <el-table-column
        label="Name"
        align="center"
        min-width="300"
        prop="name"
      ></el-table-column>
      <el-table-column
        label="CC"
        align="center"
        min-width="160"
        prop="shortName"
      ></el-table-column>
         <el-table-column
        label="Area Code"
        align="center"
        min-width="160"
        prop="areaCode"
      ></el-table-column>
      <el-table-column label="Langauge" min-width="160" align="center">
        <template v-slot="{ row }">
          <div>
            <i class="el-icon-document-copy langaugeDetails" @click="showLangaugeDetail(row)"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="International Name"
        min-width="180"
        align="center"
        prop="internationalName"
      ></el-table-column>
      <el-table-column
        label="Remark"
        min-width="160"
        align="center"
        prop="remark"
      >
        <template v-slot="{ row }">
          <dropdown
            :row="{
              id: row.id,
              nameCode: row.nameCode
            }"
            methodStr="editRegion"
            :text="`${row.remark || 'Please select'}`"
            @get-list="getList"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="Validity"
        min-width="100"
        align="center"
      >
        <template v-slot="{ row }">
          <el-switch v-model="row.switchStatus" :active-value="true" :inactive-value="false" @change="switchHandle(row)"></el-switch>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Repush" min-width="160" align="center">
        <template v-slot="{ row }">
           <el-button
           type="text"
            @click="changeRepushStatus(row, $event)"
          >Repush</el-button>
        </template>
      </el-table-column> -->
      <el-table-column
        label="Operation"
        min-width="100"
        align="center"
        prop="shortName"
      >
        <template v-slot="{ row }">
          <el-link type="primary" :underline="false" @click="edit(row)"
            >Edit</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="Log" min-width="160" align="center">
        <template v-slot="{ row }">
          <OperationType :dataType="'Standard_Region'" :dataId="row.id" />
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" style="margin: 20px 0 0 0">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row>
    <el-dialog
      :title="dialog.title"
      width="560px"
      :visible.sync="dialog.show"
      :close-on-click-modal="dialog.clickModal"
    >
      <el-form
        :model="dialogForm"
        :rules="rules"
        label-width="145px"
        size="mini"
        :ref="dialog.ref"
      >
        <el-form-item label="Region ID:" v-if="dialogForm.id">
          {{ dialogForm.id }}
        </el-form-item>
        <el-form-item label="Region Name:" prop="name" required>
          <el-input
            placeholder="Please typing in region name"
            v-model="dialogForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="CC:">
          <el-input
            placeholder="Please typing in CC"
            v-model="dialogForm.shortName"
          ></el-input>
        </el-form-item>
        <el-form-item label="Area Code:">
          <el-input
            placeholder="Please typing in Area Code"
            v-model="dialogForm.areaCode"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="Introduction:">
          <el-input
            placeholder="Please typing in Introduction"
            v-model="dialogForm.introduction"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="Remark:">
          <el-input
            placeholder="Please typing in Remark"
            v-model="dialogForm.remark"
          ></el-input>
        </el-form-item>
        <el-form-item label="International Name:" v-if="dialogForm.id">
          <el-table
            :data="dialogForm.list"
            :show-header="false"
            style="width: 100%"
          >
            <el-table-column prop="key" label="">
            </el-table-column>
            <el-table-column prop="value" label="">
              <template v-slot="{row}">
                <input type="text" :disabled="row.key === 'ENG'" v-model="row.value">
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="Flag:" v-if="dialogForm.id">
            <logo-upload :url.sync="dialogForm.logoUrl" />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :loading="dialog.isLoading"
            @click="dialogForm.id ? dialogEdit() : dialogSubmit()"
            >Confirm</el-button
          >
          <el-button @click="dialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="langDialogData.title"
      width="350px"
      :visible.sync="langDialogData.show"
      :close-on-click-modal="langDialogData.clickModal"
    >
      <el-table
        v-loading="langDialogData.loading"
        :data="langDialogData.data"
        :show-header="false"
        style="width: 100%"
      >
        <el-table-column prop="key" label="" width="100">
        </el-table-column>
        <el-table-column prop="value" label="" width="100">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRegionList as getData,
  addRegion as addData,
  editRegion as editData,
  findRegionId,
  getThirdSportRegionById,
  updateThirdSportRegionById,
  regionValidity,
  regionRePush
} from '@/service/standard'
import TableMixin from '@/mixins/standard/tableSelect'
import { Dropdown, LogoUpload } from '@/components'
import { cloneDeep } from 'lodash'
import StatusIcon from '@/components/StatusIcon'
export default {
  name: 'Region',
  mixins: [TableMixin],
  components: { Dropdown, LogoUpload, StatusIcon },
  data() {
    return {
      methodStr: 'putStandardRegion',
      extraData: {
        regionName: ''
      },
      flagImg: '',
      langDialogData: {
        title: 'Langauge Detail',
        show: false,
        clickModal: false,
        data: [],
        loading: false
      },
      expandData: {},
      rules: {
        name: [
          {
            message: 'Please typing in region name',
            required: true,
            pattern: '[^ \x22]+'
          }
        ]
      }
    }
  },
  watch: {
    'dialogForm.name' () {
      this.dialogForm.list && this.dialogForm.list.forEach(e => {
        if (e.key === 'ENG') {
          e.value = this.dialogForm.name
        }
      })
    },
    'extraData.id' () {
      if (this.extraData.id) {
        this.extraData.name = ''
      }
    }
  },
  methods: {
    changeRepushStatus(row) {
      regionRePush(row.id).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
          }
        })
    },
    getData,
    addData,
    editData,
    getList() {
      this.loading = true
      const extraData = this.fliterParams(this.extraData) || {}
      console.log(extraData)
      this.getData({ ...this.queryData, ...extraData })
        .then(res => {
          const data = res.data
          if (res.code === 0) {
            this.list = data.records
            this.list = this.list.map(item => ({
              ...item,
              switchStatus: item.deleteFlag === 1,
            }))
            this.total = data.total
            this.loading = false
          }
        })
        .catch(err => {
          this.list = []
          this.loading = false
        })
    },
    async edit(row) {
      let list = []
      await findRegionId({regionId: row.id}).then(res => {
        if (res.code === 0) {
          list = this.languageStort(res.data.region[0].language)
        }
      })
      const data = {
        id: row.id,
        name: row.name,
        oldRegionName: row.name,
        nameCode: row.nameCode,
        remark: row.remark,
        shortName: row.shortName,
        areaCode: row.areaCode,
        introduction: row.introduction,
        logoUrl: row.logoUrl,
        list: list
      }
      this.dialogShow(1, data)
    },
    expandChange(row) {
      let { id } = row
      this.upDataExpandData(id, {
        loading: true,
        data: null
      })
      getThirdSportRegionById({regionId: id})
        .then(res => {
          this.upDataExpandData(id, {
            loading: false,
            data: res.data
          })
        })
        .catch(() => {
          this.upDataExpandData(id, {
            loading: false,
            data: null
          })
        })
    },
    upDataExpandData(id, data) {
      let expandData = cloneDeep(this.expandData)
      expandData[id] = data
      this.expandData = expandData
    },
    remove (row, propsRow) {
      this.$msgbox({
        title: '',
        type: 'warning',
        message: `Remove the mapping ?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(action => {
        updateThirdSportRegionById({thirdRegionId: row.id}).then(res => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
            this.expandChange(propsRow)
          }
        })
      })
    },
    switchHandle(row) {
      if (row.switchStatus) {
        this.$msgbox({
          title: '',
          type: 'warning',
          message: `Switch to invalid?`,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then(action => {
          regionValidity({regionId: row.id, validity: row.switchStatus ? 1 : 0}).then(res => {
            if (res.code === 0) {
              this.$message({
                message: 'success',
                type: 'success'
              })
            }
          })
          setTimeout(() => {
            this.getList()
          },1000)
        }).catch(action => {
          row.switchStatus = !row.switchStatus
        })
      } else {
        this.$msgbox({
          title: '',
          type: 'warning',
          message: `Switch to Valid?`,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then(action => {
          regionValidity({regionId: row.id, validity: row.switchStatus ? 1 : 0}).then(res => {
            if (res.code === 0) {
              this.$message({
                message: 'success',
                type: 'success'
              })
            }
          })
          setTimeout(() => {
            this.getList()
          },1000)
        }).catch(action => {
          row.switchStatus = !row.switchStatus
        })
      }
    },
    loadJsonFromFile(file, fileList) {
      this.flagImg = file
    },
    handleRemove(file) {
    },
    dialogEdit () {
      this.dialog.isLoading = true
      let languages = {}
      this.dialogForm.list.map((e) => {
        languages[e.key] = e.value;
      })
      let data = {
        id: this.dialogForm.id,
        name: this.dialogForm.name,
        oldRegionName: this.dialogForm.name,
        nameCode: this.dialogForm.nameCode,
        remark: this.dialogForm.remark,
        shortName: this.dialogForm.shortName,
        areaCode: this.dialogForm.areaCode,
        introduction: this.dialogForm.introduction,
        logoUrl: this.dialogForm.logoUrl,
        languagesJson: JSON.stringify(languages)
      }
      editData(data).then(res => {
        if (res.code === 0) {
          this.$message({
            message: 'success',
            type: 'success'
          })
          this.getList()
        }
        this.dialog.isLoading = false
        this.dialog.show = false
      })
    },
    languageStort (res) {
      let list = []
      let Values = Object.values(res)
      Object.keys(res).forEach((e, index) => {
        list.push({
          key : e,
          value : Values[index]
        })
      })
      list.sort(function(a,b){
        var enumeration = ["ENG", "ZHO", "CMN", "JPN", "KOR","SPA"]
        return enumeration.indexOf(a.key) - enumeration.indexOf(b.key)
      })
      return list
    },
    showLangaugeDetail (row) {
      this.langDialogData = {
        ...this.langDialogData,
        show: true,
        loading: true,
        data: []
      }
      findRegionId({regionId: row.id}).then(res => {
        if (res.code === 0) {
          this.langDialogData = {
            ...this.langDialogData,
            loading: false,
            data: this.languageStort(res.data.region[0].language)
          }
        }
      }).catch(() => {
        this.langDialogData = {
          ...this.langDialogData,
          show: true,
          loading: false,
          data: []
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .warp {
    .flagImg {
      width: 30px;
      height: 30px;
    }
    .langaugeDetails {
      font-size: '20px';
      cursor: 'pointer';
    }
  }
</style>