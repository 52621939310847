<template>
  <div class="warp">
    <el-form inline size="mini" @submit.native.prevent="search">
      <el-form-item label="Match ID:">
        <el-input
          :style="{ width: '240px' }"
          v-Int
          v-model="extraData.matchId"
          type="number"
          clearable
          placeholder="Please enter match id"
        ></el-input>
      </el-form-item>
      <el-form-item label="Date:">
        <date-range
          :isOutright="isOutright"
          :utc4="isOutright ? 0 : 1"
          type="datetimerange"
          v-model="dateRange"
          @change="dateRangeFn"
        />
      </el-form-item>
      <el-form-item label="Sport:">
        <select-box
          :url="STD_SEARCH_SPORT"
          :queryOrderParams="{ page: 1, size: 500 }"
          :value.sync="extraData.sportId"
          search-key="name"
          label-key="sportsName"
          value-key="id"
        />
      </el-form-item>
      <el-form-item label="Region:">
        <select-box
          :url="STD_SEARCH_REGION"
          :queryOrderParams="{ page: 1, size: 500, deleteFlag: 1 }"
          :value.sync="extraData.regionId"
          search-key="name"
          label-key="name"
          value-key="id"
        />
      </el-form-item>

      <el-form-item label="Tournament:">
        <select-box
          :url="STD_SEARCH_TOURNAMENT"
          :queryOrderParams="{ page: 1, size: 100 }"
          :value.sync="extraData.tournamentId"
          search-key="name"
          label-key="name"
          value-key="id"
        />
      </el-form-item>
      <el-form-item label="Status:">
        <select-box
          width="200"
          multiple="multiple"
          :options="dataStatusOptions"
          :value.sync="extraData.dataStatus"
        />
      </el-form-item>
      <el-form-item label="Match Status:" v-if="!isOutright">
        <el-select
          clearable
          v-model="extraData.matchStatus"
          placeholder="Please typing in keyword and select"
        >
          <el-option
            v-for="item in matchStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Ignore" v-if="!isOutright">
        <el-select v-model="extraData.ignore" placeholder="" clearable="">
          <el-option label="Yes" :value="1"></el-option>
          <el-option label="No" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-checkbox
          label="Third Match Not Pushed"
          :false-label="0"
          :true-label="1"
          v-model="extraData.thirdMatchNotPushed"
        ></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >Search</el-button
        >
        <el-button type="primary" icon="el-icon-plus" v-if="hasCreateMatch || hasCreateOutrightMatch" @click="(creatMatchDialog = true) && (dialogForm = {},currentTournament = {})"
          >Create Match</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      :key="matchType"
      stripe
      v-loading="loading"
      @sort-change="sort"
      :max-height="tableHeight"
      @expand-change="expandChange"
      class="match-table"
      row-key="id"
      @cell-mouse-enter="cellEnterHover"
      @cell-mouse-leave="cellLeaveHover"
    >
      <el-table-column type="expand" width="150">
        <template slot-scope="props">
          <el-table
            class="expand-table"
            v-loading="expandData[props.row.id].loading"
            :data="expandData[props.row.id].data"
            style="width: 100%"
          >
            <el-table-column
              label="Source Data"
              width="140"
              align="center"
              prop="dataSourceCode"
            >
              <template v-slot="{ row }">
                {{ originMapping[row.dataSourceCode] }}
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="Check Status"
              min-width="100"
              align="center"
            >
              <template v-slot="{ row }">
                <status-icon
                  v-if="row.checkStatus"
                  :status="checkStatusTexts[row.checkStatus]"
                />
              </template>
            </el-table-column> -->
            <el-table-column
              label="ID"
              width="140"
              align="center"
              prop="sourceMatchId"
            >
              <template v-slot="{ row }">
                {{ row.sourceMatchId }}
                <status-icon
                  v-if="row.checkStatus"
                  :status="checkStatusTexts[row.checkStatus]"
                />
              </template>
            </el-table-column>

            <el-table-column
              label="Sport"
              width="140"
              align="center"
              prop="sportName"
            >
              <template v-slot="{ row }">
                <tag-id :value="`${row.thirdSport}ID:${row.sourceSportId}`" />
              </template>
            </el-table-column>

            <el-table-column
              v-if="!isOutright"
              label="Date"
              width="164"
              align="center"
            >
              <template v-slot="{ row }">{{
                row.beginTime | formatTime
              }}</template>
            </el-table-column>
            <el-table-column
              v-if="isOutright"
              label="Start Time"
              width="164"
              align="center"
            >
              <template v-slot="{ row }">{{
                row.beginTime | formatTime
              }}</template>
            </el-table-column>
            <el-table-column
              v-if="isOutright"
              label="End Time"
              width="164"
              align="center"
            >
              <template v-slot="{ row }">{{
                row.endTime | formatTime
              }}</template>
            </el-table-column>
            <el-table-column
              label="Region"
              width="160"
              align="center"
              prop="regionName"
            >
              <template v-slot="{ row }">
                <tag-id :value="`${row.thirdRegion}ID:${row.sourceRegionId}`" />
              </template>
            </el-table-column>
            <el-table-column
              label="Tournament"
              width="200"
              align="center"
              prop="tournament"
            >
              <template v-slot="{ row }">
                <tag-id
                  :value="`${row.thirdTournament}ID:${row.sourceTournamentId}`"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="Match"
              width="200"
              align="left"
              prop="tournament"
              v-if="isOutright"
            >
              <template v-slot="{ row }">
                {{ row.name || row.thirdTournament }} {{ row.year }}
              </template>
            </el-table-column>
            <el-table-column
              label="Home"
              width="160"
              align="center"
              prop="home"
              v-if="!isOutright"
            >
              <template v-slot="{ row }">
                <tag-id :value="`${row.thirdHome}ID:${row.sourceHomeTeamId}`" />
              </template>
            </el-table-column>
            <el-table-column
              label="Away"
              width="160"
              align="center"
              prop="away"
              v-if="!isOutright"
            >
              <template v-slot="{ row }">
                <tag-id :value="`${row.thirdAway}ID:${row.sourceAwayTeamId}`" />
              </template>
            </el-table-column>
            <el-table-column
              label="Match Status"
              width="160"
              align="center"
              prop="matchStatusText"
              v-if="!isOutright"
            >
              <template slot-scope="scope">
                <p>{{ scope.row.matchStatus === null ? '' :matchStatusList[scope.row.matchStatus].label }}</p>
              </template>
            </el-table-column>
            <el-table-column
              label="Match Format"
              v-if="!isOutright"
              width="160"
              align="center"
              prop="matchStatusText"
            >
              <template slot-scope="scope">
                <p>{{ formatObj[scope.row.matchFormat] }}</p>
              </template>
            </el-table-column>
            <el-table-column
              label="Remove"
              width="160"
              align="center"
              prop="away"
            >
              <template v-slot="{ row }">
                <status-icon
                  @click.native="remove(row, props.row)"
                  :status="'delete'"
                />
              </template>
            </el-table-column>
            <el-table-column label="Push Data" :width="isOutright? 155 :140" align="center">
              <template v-slot="{ row }">
                <el-switch
                  :value="row.switchStatus"
                  :active-value="true"
                  :inactive-value="false"
                  @change="thirdPushData(row, props.row)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column
              label="Market Mapping"
              width="180"
              align="center"
              prop="matchStatusText"
              v-if="isOutright"
            >
              <template v-slot="{ row }">
                <el-button
                  type="text"
                  v-if="+row.dataSourceCode === 2 || +row.dataSourceCode === 3"
                  @click="marketMappingFn(row)"
                  >Mapping</el-button
                >
              </template>
            </el-table-column>

            <!-- <el-table-column label="" width="140"></el-table-column> -->
            <el-table-column
              label="Neutral"
              min-width="140"
              align="center"
              v-if="!isOutright"
            >
              <template v-slot="{ row }">
                {{ row.neutralGround ? 'Yes' : 'No' }}
              </template>
            </el-table-column>
            <el-table-column
              label="Phase"
              width="160"
              align="center"
              prop="phase"
              v-if="!isOutright"
            >
            </el-table-column>
            <!-- <el-table-column label="Ignore" min-width="140" align="center" v-if="!isOutright">
              <template v-slot="{ row }">
                {{row.ignore ? 'Yes' : 'No'}}
              </template>
            </el-table-column> -->
          <el-table-column
              label="Player"
              min-width="160"
              align="center"
              v-if="!isOutright"
            >
              <template v-slot="{ row }">
                <el-link :disabled="!row.playerMarket" type="primary" @click="linkEdit(row)">Edit</el-link>
                <!-- {{ row.neutralGround ? 'Yes' : 'No' }} -->
              </template>
            </el-table-column>
            <el-table-column label="Reverse" v-if="!isOutright" min-width="236">
              <template v-slot="{ row }">
                <el-switch
                  v-model="row.isReverse"
                  :active-value="1"
                  :inactive-value="0"
                  @change="changeReverse(row, $event, props.row)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column
              label="Support Live"
              width="150"
              align="center"
              prop="dataSourceCode"
              v-if="!isOutright"
            >
              <template v-slot="{ row }">
                <!-- <el-switch
                :value="!!row.liveOddsSupport"
                @change="switchLiveAndEvent(props.row, row, 'inPlayDatasource')"
              ></el-switch> -->
                {{ row.liveOddsSupport || row.liveDataSupport ? 'Yes' : 'No' }}
              </template>
            </el-table-column>
     
            <el-table-column
              label="Booked"
              width="140"
              align="center"
              prop="dataSourceCode"
              v-if="!isOutright"
            >
              <template v-slot="{ row }">
                {{ row.liveOddsBooked || row.liveDataBooked ? 'Yes' : 'No' }}
                <!-- <el-switch
                :value="!!row.liveDataSupport"
                @change="switchLiveAndEvent(props.row, row, 'liveDataDatasource')"
              ></el-switch> -->
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="Booked Live Odds"
              width="140"
              align="center"
              prop="dataSourceCode"
              v-if="!isOutright"
            >
            <template v-slot="{ row }">
              <el-switch
                :value="!!row.liveOddsBooked"
                @change="switchLiveAndEvent(props.row, row, 'bookedInPlayDatasource')"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column
              label="Booked Event"
              width="140"
              align="center"
              prop="dataSourceCode"
              v-if="!isOutright"
            >
            <template v-slot="{ row }">
              <el-switch
                :value="!!row.liveDataBooked"
                @change="switchLiveAndEvent(props.row, row, 'bookedLiveDataDatasource')"
              ></el-switch>
            </template>
          </el-table-column> -->
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        label="Match ID"
        sortable="custom"
        width="140"
        align="center"
        prop="id"
      ></el-table-column>
      <el-table-column label="Sport" min-width="140" align="center">
        <template v-slot="{ row }">
          <tag-id :value="`${row.sportName}ID:${row.sportId}`" />
        </template>
      </el-table-column>
      <el-table-column
        label="Date"
        sortable="custom"
        min-width="164"
        align="center"
        prop="beginTime"
        v-if="!isOutright"
      >
        <template v-slot="{ row }">
          <el-popover placement="bottom" width="220" trigger="click">
            <div class="block">
              <el-date-picker
                v-model="row.beginTime"
                @change="updateTime(row)"
                type="datetime"
                placeholder="date"
              >
              </el-date-picker>
            </div>
            <p class="time" slot="reference">
              {{ parseTime(row.beginTime, '{y}-{m}-{d} {h}:{i}:{s}') }}
            </p>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        label="Start Time"
        sortable="custom"
        min-width="168"
        align="center"
        prop="beginTime"
        v-if="isOutright"
      >
        <template v-slot="{ row }">
          <p>{{ parseTime(row.beginTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</p>
        </template>
      </el-table-column>
      <el-table-column
        label="End Time"
        sortable="custom"
        min-width="168"
        align="center"
        prop="endTime"
        v-if="isOutright"
      >
        <template v-slot="{ row }">
          <el-popover placement="bottom" width="220" trigger="click">
            <div class="block">
              <el-date-picker
                v-model="row.endTime"
                @change="updateTime(row)"
                type="datetime"
                placeholder="date"
              >
              </el-date-picker>
            </div>
            <p class="time" slot="reference">{{ row.endTime | formatTime }}</p>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        label="Region"
        min-width="160"
        align="center"
        prop="regionName"
      >
        <!-- <template v-slot="{ row }">
          <tag-id :value="`${row.regionName}ID:${row.regionId}`" />
        </template> -->
        <template slot-scope="scope">
          <el-select
            :disabled="scope.row.switchStatus"
            filterable
            v-model="scope.row.regionId"
            @change="eidtRegion(scope.row, scope.$index)"
            placeholder="Please"
          >
            <el-option
              v-for="item in regionList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        label="Tournament"
        min-width="200"
        align="center"
        prop="tournament"
      >
        <template v-slot="{ row }">
          <img
            v-if="row.tournamentLogo"
            class="logoIcon"
            :src="row.tournamentLogo"
            alt=""
          />
          <tag-id :value="`${row.tournament}ID:${row.tournamentId}`" />
        </template>
      </el-table-column>
      <el-table-column
        label="Match"
        min-width="200"
        align="left"
        prop="tournament"
        v-if="isOutright"
      >
        <template v-slot="{ row }">
          <!-- <img v-if="row.tournamentLogo" class="logoIcon" :src="row.tournamentLogo" alt=""> -->
          <!-- <tag-id :value="`${row.tournament}ID:${row.tournamentId}`" /> -->
          {{ row.name || row.tournament }} {{ row.year }}
        </template>
      </el-table-column>
      <el-table-column
        label="Home"
        min-width="160"
        align="center"
        prop="home"
        v-if="!isOutright"
      >
        <template v-slot="{ row }">
          <img
            v-if="row.homeLogo"
            class="logoIcon"
            :src="row.homeLogo"
            alt=""
          />
          <tag-id :value="`${row.home}ID:${row.homeTeamId}`" /> </template
      ></el-table-column>
      <el-table-column
        label="Away"
        min-width="160"
        align="center"
        prop="away"
        v-if="!isOutright"
      >
        <template v-slot="{ row }">
          <img
            v-if="row.awayLogo"
            class="logoIcon"
            :src="row.awayLogo"
            alt=""
          />
          <tag-id :value="`${row.away}ID:${row.awayTeamId}`" /> </template
      ></el-table-column>
      <el-table-column
        label="Match Status"
        min-width="160"
        align="center"
        prop="matchStatusText"
        v-if="!isOutright"
      >
        <template slot-scope="scope">
          <el-select
            :disabled="scope.row.switchStatus"
            v-model="scope.row.matchStatus"
            :class="{matchTips: ![0,4,5].includes(+scope.row.matchStatus)}"
            @change="eidtMatchStatus(scope.row, scope.$index)"
            placeholder="Please"
          >
            <el-option
              v-for="item in matchStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        label="Match Format"
        min-width="160"
        align="center"
        prop="matchStatusText"
        v-if="!isOutright"
      >
        <template slot-scope="scope">
          <el-select
            :disabled="scope.row.switchStatus"
            v-model="scope.row.matchFormat"
            @change="editFormat(scope.row, scope.$index)"
            placeholder="Select"
          >
            <el-option
              v-for="item in formatList.filter(
                (item) => item.sportId === scope.row.sportId
              )"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        label="Data Status"
        min-width="160"
        align="center"
        prop="dataStatusText"
      ></el-table-column>
      <el-table-column label="Visible" min-width="140" align="center">
        <template v-slot="{ row }">
          <el-switch
            :value="row.switchStatus"
            :active-value="true"
            :inactive-value="false"
            @change="switchHandle(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="Neutral"
        min-width="140"
        align="center"
        v-if="!isOutright"
      >
        <template v-slot="{ row }">
          <el-switch
            :value="row.neutralGround"
            :active-value="true"
            :inactive-value="false"
            @change="switchNeutralGround(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="Phase"
        min-width="160"
        align="center"
        v-if="!isOutright"
      >
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.phase"
            @change="eidtPhase(scope.row, scope.$index)"
            placeholder="Please"
          >
            <el-option
              v-for="item in phaseOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
         <el-table-column label="Player Number" min-width="160" align="center"  v-if="!isOutright">
        <template v-slot="{ row }">
           <el-switch
            active-color=""
            :value="row.playerNumberStatus"
            :active-value="true"
            :inactive-value="false"
            @change="changePlayerNumberStatus(row, $event)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="Ignore" v-if="!isOutright">
        <template v-slot="{ row }">
          <el-switch
            v-model="row.ignore"
            :disabled="row.dataStatus === 1"
            :active-value="1"
            :inactive-value="0"
            @change="changeIgnore(row, $event)"
          ></el-switch>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Is Hot" min-width="140" align="center">
        <template v-slot="{ row }">
          <div>
            <el-form
              v-if="editHotData.id === row.id"
              :model="editHotData"
              label-width="0"
              :rules="editHotRules"
              size="mini"
              :style="{ height: '55px' }"
            >
              <el-form-item prop="hot">
                <el-input
                  v-model="editHotData.hot"
                  clearable
                  placeholder="Please enter hot"
                  ref="hotInput"
                  @blur="hotBlur($event, row)"
                ></el-input>
              </el-form-item>
            </el-form>

            <div v-else class="hot-edit-edit" @click="editHotIdFunc(row)">
              <span>{{ row.hot }}</span>
              <i class="el-icon-edit"></i>
            </div>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column label="Langauge" min-width="160" align="center">
        <template v-slot="{ row }">
          <div>
            <status-icon v-if="row.langData" :status="row.langData.status" />
            <i
              class="el-icon-document-copy"
              :style="{ fontSize: '20px', cursor: 'pointer' }"
              @click="showLangaugeDetail(row)"
            ></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Livestream" min-width="160" align="center">
        <template v-slot="{ row }">
          <div style="display: flex; flex-wrap: wrap; justify-content: flex-end;">
           <el-switch
            inactive-text="Animation:"
            active-color=""
            :value="row.animateStatus"
            :active-value="true"
            :inactive-value="false"
            @change="changeAnimation(row, $event)"
          ></el-switch>
          <br>
           <el-switch
            inactive-text="Video:"
            :value="row.videoStatus"
            :active-value="true"
            :inactive-value="false"
            @change="changeVideo(row, $event)"
          ></el-switch>
          </div>
        </template>
      </el-table-column>
    
       <!-- <el-table-column label="Repush" min-width="160" align="center">
        <template v-slot="{ row }">
           <el-button
           type="text"
            @click="changeRepushStatus(row, $event)"
          >Repush</el-button>
        </template>
      </el-table-column> -->
      <el-table-column label="Log" min-width="160" align="center">
        <template v-slot="{ row }">
          <OperationType :dataType="'Standard_Match'" :dataId="row.id" />
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" style="margin-top: 20px">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row>
    <el-dialog ref="createMatch" title="Create Match" width="606px" :visible.sync="creatMatchDialog">
      <el-form :model="dialogForm" label-width="120px" size="mini">
           <el-form-item
          label="Sport:"
          required
        >
          <select-box
            @change="dialogForm.matchFormat && (dialogForm.matchFormat = '')"
            :url="STD_SEARCH_SPORT"
            :queryOrderParams="{ page: 1, size: 500 }"
            :value.sync="dialogForm.sportId"
            :label="dialogForm.sportsName"
            search-key="name"
            label-key="sportsName"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Date" required="" v-if="!isOutright">
          <el-date-picker v-model="dialogForm.beginTime" type="datetime" value-format="timestamp" placeholder="Select"></el-date-picker>
        </el-form-item>
        <el-form-item label="Start Time" required="" v-if="isOutright">
          <el-date-picker v-model="dialogForm.beginTime" type="datetime" value-format="timestamp" placeholder="Select"></el-date-picker>
        </el-form-item>
        <el-form-item label="End Time" required="" v-if="isOutright">
          <el-date-picker v-model="dialogForm.endTime" type="datetime" value-format="timestamp" placeholder="Select"></el-date-picker>
        </el-form-item>
        <el-form-item
          label="Region:"
        >
          <el-select disabled v-model="currentTournament.regionId" placeholder="Select">
            <el-option :label="currentTournament.regionName" :value="currentTournament.regionId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Tournament:"
          required=""
        >
        <span> ID </span>
              <el-input
                v-model="dialogForm.tournamentId"
                @change="inputTeam($event, 3)"
                style="width: 130px"
                placeholder="Input"
              ></el-input>
              <span> Name </span>
          <select-box
            :url="STD_SEARCH_TOURNAMENT"
            ref="selectTournament"
            @change="(e,data) => currentTournament = data || {}"
            :queryOrderParams="{
              page: 1,
              size: 100,
              sportId: dialogForm.sportId,
              isVisible: 1
            }"
            :value.sync="dialogForm.tournamentId"
            :label="dialogForm.tournament"
            search-key="name"
            label-key="name"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Home:" required="" v-if="!isOutright">
           <span> ID </span>
              <el-input
                v-model="dialogForm.homeTeamId"
                @change="inputTeam($event, 1)"
                style="width: 130px"
                placeholder="Input"
              ></el-input>
              <span> Name </span>
              <select-box
                :url="STD_SEARCH_TEAM"
                ref="teamH"
                :queryOrderParams="{
                  sportId: dialogForm.sportId,
                  page: 1,
                  size: 100,
                  isVisible: 1
                }"
                :value.sync="dialogForm.homeTeamId"
                :label="dialogForm.homeName"
                search-key="name"
                label-key="name"
                value-key="id"
              />
          </el-form-item>
          <el-form-item label="Away:" required="" v-if="!isOutright">
            <span> ID </span>
              <el-input
                v-model="dialogForm.awayTeamId"
                @change="inputTeam($event, 0)"
                style="width: 130px"
                placeholder="Input"
              ></el-input>
              <span> Name </span>
              <select-box
                :url="STD_SEARCH_TEAM"
                ref="teamA"
                :queryOrderParams="{
                  sportId: dialogForm.sportId,
                  page: 1,
                  size: 100,
                  isVisible: 1
                }"
                :value.sync="dialogForm.awayTeamId"
                :label="dialogForm.awayName"
                search-key="name"
                label-key="name"
                value-key="id"
              />
          </el-form-item>
          <el-form-item label="Match Status:"  required="" v-if="!isOutright">
            <el-select
          clearable
          v-model="dialogForm.matchStatus"
          placeholder="Please typing in keyword and select"
        >
          <el-option
            v-for="item in matchStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
          </el-form-item>
          <el-form-item label="Match Format:"  v-if="!isOutright">
             <el-select
            v-model="dialogForm.matchFormat"
            placeholder="Select"
          >
            <el-option
              v-for="item in formatList.filter(
                (item) => item.sportId ===dialogForm.sportId
              )"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            >
            </el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="Neutral:"  v-if="!isOutright">
             <el-switch
            v-model="dialogForm.neutralGround"
            :active-value="true"
            :inactive-value="false"
          ></el-switch>
          </el-form-item>
          <el-form-item label="Phase:"  v-if="!isOutright">
              <el-select
            v-model="dialogForm.phase"
            placeholder="Please"
          >
            <el-option
              v-for="item in phaseOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          </el-form-item>
        <el-form-item label="Season" v-if="isOutright">
          <el-input v-model="dialogForm.year" placeholder="Input"></el-input>
        </el-form-item>
        <el-form-item label="">
           <el-button @click="creatMatchDialog = false">Cancel</el-button>
           <el-button type="primary" @click="createMatch">Confirm</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="Please Check the Match Status Again"
      :visible.sync="dialogTableVisible"
    >
      <el-table :data="gridData">
        <el-table-column
          property="dataSourceCode"
          label="Source Data"
          width="130"
        >
          <template v-slot="{ row }">
            {{ originMapping[row.dataSourceCode] || 'Standard Match' }}
          </template>
        </el-table-column>
        <el-table-column property="id" label="Match ID" width="120">
          <template v-slot="{ row }">
            {{ row.sourceMatchId || row.id }}
          </template>
        </el-table-column>
        <el-table-column property="sportName" label="Sport" width="120">
          <template slot-scope="scope">
            <p v-if="scope.$index === 0">{{ scope.row.sportName }}</p>
            <p
              v-else
              :style="
                thirdCompared(
                  gridData[0].sportName,
                  scope.row.thirdSport,
                  scope.row.dataSourceCode
                )
              "
            >
              {{ scope.row.thirdSport }}
            </p>
          </template>
        </el-table-column>
        <el-table-column property="beginTime" label="Date" width="200">
          <template slot-scope="scope">
            <p v-if="scope.$index === 0">
              {{ parseTime(scope.row.beginTime, '{y}-{m}-{d} {h}:{i}:{s}') }}
            </p>
            <p
              v-else
              :style="
                thirdCompared(
                  gridData[0].beginTime,
                  scope.row.beginTime,
                  scope.row.dataSourceCode
                )
              "
            >
              {{ parseTime(scope.row.beginTime, '{y}-{m}-{d} {h}:{i}:{s}') }}
            </p>
          </template>
        </el-table-column>
        <el-table-column property="regionName" label="Region" width="120">
          <template slot-scope="scope">
            <p v-if="scope.$index === 0">{{ scope.row.regionName }}</p>
            <p
              v-else
              :style="
                thirdCompared(
                  gridData[0].regionName,
                  scope.row.thirdRegion,
                  scope.row.dataSourceCode
                )
              "
            >
              {{ scope.row.thirdRegion }}
            </p>
          </template>
        </el-table-column>
        <el-table-column property="tournament" label="Tournament" width="200">
          <template slot-scope="scope">
            <p v-if="scope.$index === 0">{{ scope.row.tournament }}</p>
            <p
              v-else
              :style="
                thirdCompared(
                  gridData[0].tournament,
                  scope.row.thirdTournament,
                  scope.row.dataSourceCode
                )
              "
            >
              {{ scope.row.thirdTournament }}
            </p>
          </template>
        </el-table-column>
        <el-table-column property="home" label="Home Team" width="200">
          <template slot-scope="scope">
            <p v-if="scope.$index === 0">{{ scope.row.home }}</p>
            <p
              v-else
              :style="
                thirdCompared(
                  gridData[0].home,
                  scope.row.thirdHome,
                  scope.row.dataSourceCode
                )
              "
            >
              {{ scope.row.thirdHome }}
            </p>
          </template>
        </el-table-column>
        <el-table-column property="away" label="Away Team" width="200">
          <template slot-scope="scope">
            <p v-if="scope.$index === 0">{{ scope.row.away }}</p>
            <p
              v-else
              :style="
                thirdCompared(
                  gridData[0].away,
                  scope.row.thirdAway,
                  scope.row.dataSourceCode
                )
              "
            >
              {{ scope.row.thirdAway }}
            </p>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="dialogTableVisible = false">Cancel</el-button>
        <el-button type="primary" @click="openLangaugeDetail">Next</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="langDialogData.title"
      width="1100px"
      :visible.sync="langDialogData.show"
      :close-on-click-modal="langDialogData.clickModal"
    >
      <el-table
        v-loading="langDialogData.loading"
        :data="langDialogData.data"
        style="width: 100%"
      >
        <el-table-column prop="lang" label="" width="60">
          <template v-slot="{ row }">
            <h4>{{ row.lang }}</h4>
          </template>
        </el-table-column>
        <el-table-column prop="sport" label="Sport">
          <template slot-scope="scope">
            <span v-if="scope.row.lang === 'ENG' || langDialogData.btnStatus">{{
              scope.row.sport
            }}</span>
            <span v-else-if="!langStatus">{{ scope.row.sport }}</span>
            <el-input
              v-else
              type="textarea"
              v-model="scope.row.sport"
              placeholder=""
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="sport" label="Logo" width="55">
          <template v-slot="{ row }">
            <img
              v-if="row.regionLogo"
              class="logoIcon"
              :src="row.regionLogo"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column prop="region" label="Region">
          <template slot-scope="scope">
            <span v-if="scope.row.lang === 'ENG' || langDialogData.btnStatus">{{
              scope.row.region
            }}</span>
            <span v-else-if="!langStatus">{{ scope.row.region }}</span>
            <el-input
              v-else
              type="textarea"
              v-model="scope.row.region"
              placeholder=""
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="sport" label="Logo" width="55">
          <template v-slot="{ row }">
            <img
              v-if="row.tournamentLogo"
              class="logoIcon"
              :src="row.tournamentLogo"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column prop="tournament" label="Tournament">
          <template slot-scope="scope">
            <span v-if="scope.row.lang === 'ENG' || langDialogData.btnStatus">{{
              scope.row.tournament
            }}</span>
            <span v-else-if="!langStatus">{{ scope.row.tournament }}</span>
            <el-input
              v-else
              type="textarea"
              v-model="scope.row.tournament"
              placeholder=""
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="sport"
          label="Logo"
          width="55"
          v-if="!isOutright"
        >
          <template v-slot="{ row }">
            <img
              v-if="row.homeTeamLogo"
              class="logoIcon"
              :src="row.homeTeamLogo"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column prop="homeTeam" label="HomeTeam" v-if="!isOutright">
          <template slot-scope="scope">
            <span v-if="scope.row.lang === 'ENG' || langDialogData.btnStatus">{{
              scope.row.homeTeam
            }}</span>
            <span v-else-if="!langStatus">{{ scope.row.homeTeam }}</span>
            <el-input
              v-else
              type="textarea"
              v-model="scope.row.homeTeam"
              placeholder=""
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="sport"
          label="Logo"
          width="55"
          v-if="!isOutright"
        >
          <template v-slot="{ row }">
            <img
              v-if="row.awayTeamLogo"
              class="logoIcon"
              :src="row.awayTeamLogo"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column prop="awayTeam" label="AwayTeam" v-if="!isOutright">
          <template slot-scope="scope">
            <span v-if="scope.row.lang === 'ENG' || langDialogData.btnStatus">{{
              scope.row.awayTeam
            }}</span>
            <span v-else-if="!langStatus">{{ scope.row.awayTeam }}</span>
            <el-input
              v-else
              type="textarea"
              v-model="scope.row.awayTeam"
              placeholder=""
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="awayTeam" label="Match" v-if="isOutright">
          <template slot-scope="scope">
            <span v-if="langDialogData.btnStatus">{{
              scope.row.match
            }}</span>
            <span v-else-if="!langStatus">{{ scope.row.match }}</span>
            <el-input
              v-else
              type="textarea"
              :disabled="!langDialogData.editMatch"
              v-model="scope.row.match"
              placeholder=""
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button
          v-if="!langDialogData.btnStatus && !langStatus"
          type="primary"
          @click="updataStatus"
          >Edit</el-button
        >
        <el-button
          v-if="!langDialogData.btnStatus && langStatus"
          type="primary"
          @click="updateLanData"
          >Confirm</el-button
        >
        <el-button
          v-if="langDialogData.btnStatus"
          type="primary"
          @click="openPushData"
          >Push</el-button
        >
        <el-button
          v-if="langDialogData.btnStatus"
          @click="langDialogData.show = false"
          >Cancel</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
/deep/.matchTips .el-input__inner{
  color: red;
  font-weight: bold;
}
.desc {
  display: inline-block;
  width: 60px;
  text-align: left;
  margin-left: 6px;
}
.time {
  color: #409eff;
  cursor: pointer;
}
.el-icon-edit {
  margin-left: 5px;
}
.logoIcon {
  width: 20px;
  height: 20px;
}
.hot-edit-edit {
  cursor: pointer;
}
/deep/ .el-switch__label.is-active{
  color: #000;
}
/deep/ .el-table__expanded-cell {
  padding-left: 10px;
}
/deep/ .el-dialog .el-table tbody tr.el-table__row:hover > td {
  background-color: #fff !important;
}
/deep/ .el-dialog .el-table tbody tr.el-table__row > td {
  .el-textarea__inner {
    padding: 0;
    resize: none;
  }
}
</style>

<script>
import {
  getMatchList as getData,
  updateAnimateStatus,
  updatePlayerNumberStatus,
  updateVideoStatus,
  matchFormatList,
  editMatchStatus,
  getSubMarches,
  getMatchLanguage,
  editMatchHot,
  updateNeutralGround,
  deleteMatch,
  editTime,
  changMatchStatus,
  getRegionList,
  editMatchRegion,
  thirdDataStatus,
  createOutrightMatch,
  validateOutrightMatch,
  createStandardMatch,
  validateStandardMatch,
  getTournamentList,
  matchRePush,
  getTeamList as findTeam,
  editInternational as editData
} from '@/service/standard'
import { updateReverse } from '@/service/subscribe'
import TableMixin from '@/mixins/standard/tableSelect'
import DateRange from '@/components/DateRange/dateRange'
import StatusIcon from '@/components/StatusIcon'
import { mapGetters } from 'vuex'
import {
  dataStatusOptions,
  matchStatusMapping,
  dataStatusMapping,
  originMapping,
  phaseOptions,
  matchStatusList,
  langData as langList
} from '@/utils/constants'
import TagId from '@/components/TagId'
import { cloneDeep } from 'lodash'
import { parseTime, getDataTimeAfterDate, deepClone } from '@/utils'
import { Promise } from 'es6-promise'
const toDay = getDataTimeAfterDate()

export default {
  name: 'Match',
  mixins: [TableMixin],
  components: {
    DateRange,
    TagId,
    StatusIcon
  },
  beforeCreate() {
    this.timerGroup = {}
  },
  data() {
    let p = /^([1-7][0-9][0-9][0-9])*$/
    const validateHot = (rule, value, callback) => {
      if (
        value === '' ||
        value === '0000' ||
        (p.test(value) && value !== '1000')
      ) {
        callback()
      } else {
        callback('Please typeing the correct value!')
      }
    }
    return {
      creatMatchDialog: false,
      originMapping,
      dataStatusOptions,
      phaseOptions,
      formatObj: {},
      dateRange: [...toDay],
      expandData: {},
      editHotData: {
        id: '',
        hot: ''
      },
      editHotRules: {
        hot: { validator: validateHot, trigger: ['blur', 'change'] }
      },
      checkStatusTexts: {
        1: 'success',
        2: 'warning',
        3: 'error'
      },
      matchStatusList,
      formatList: [],
      langDialogData: {
        title: 'Langauge Detail',
        show: false,
        clickModal: false,
        data: [],
        loading: false,
        btnStatus: false,
        editMatch: false
      },
      extraData: {
        sportId: '',
        ignore: 0,
        startTime: toDay[0],
        endTime: toDay[1],
        regionId: '',
        tournamentId: '',
        dataStatus: [],
        matchStatus: '',
        thirdMatchNotPushed: 0
      },
      currentTournament: {},
      dialogTableVisible: false,
      gridData: [],
      regionList: [],
      cloneLangData: {},
      langStatus: false,
      isOutright: '',
      matchType: 2
    }
  },
  created() {
    this.isOutright = /outright/.test(this.$route.path.toLowerCase())
    this.matchType = this.isOutright ? 1 : 2
    const status = this.$route.query.status
    if (status) {
      this.extraData.dataStatus = [+status, -3]
    }
    const pushTimeFlag = this.$route.query.formatPushTime
    if (pushTimeFlag) {
      this.extraData.dataStatus = [1]
      this.extraData.thirdMatchNotPushed = 1
    }
    // this.dateRange = [,+new Date()]
    matchFormatList().then((res) => {
      if (res.code === 0) {
        this.formatList = res.data
        const formatObj = {}
        res.data.map((item) => {
          formatObj[item.code] = item.value
        })
        this.formatObj = formatObj
      }
    })
  },
  activated() {
    if (this.active) {
      this.getList()
    }
    this.active = true
  },
  computed: {
    ...mapGetters(['functionInfoList']),
    hasCreateMatch() {
      return !this.isOutright && this.functionInfoList.some((item) => {
        return (
          item.privilegeMark === 'TMC/Page/match/createMatch' &&
          item.featureStatus !== 3
        )
      })
    },
    hasCreateOutrightMatch() {
      return this.isOutright && this.functionInfoList.some((item) => {
        return (
          item.privilegeMark === 'TMC/Page/match/createOutrightMatch' &&
          item.featureStatus !== 3
        )
      })
    }
  },
  mounted() {
    getRegionList({ name: '', page: 1, size: 1000 }).then((res) => {
      if (res.code === 0) this.regionList = res.data.records
    })
  },
  methods: {
    linkEdit(row) {
      const { href } = this.$router.resolve({
        path: '/playerMarkets',
        query: row
      })
      window.open(href, '_blank')
    },
    inputTeam(e, type = 1) {
      console.log(e, type)
      if(!e) {
        return
      }
      const refName = type === 1 ? 'teamH' : type == 0 ? 'teamA' : type === 3? 'selectTournament': 'teamSelect';
      (type === 3 ? getTournamentList({id: e}) : findTeam({ id: e })).then((res) => {
        if (res.code === 0) {
          const list = res.data.records
          if(list.length === 0) {
            if(type === 1) {
              this.dialogForm.homeTeamId = ''
            }
            if(type === 0) {
              this.dialogForm.awayTeamId = ''
            }
            if(type === 3) {
              this.dialogForm.tournamentId = ''
            }
            return
          }
          const [{ name }] = list
          this.$refs[refName].optionData = list
          this.$nextTick((e) => {
            this.$refs[refName].$refs.select.selectedLabel = name
          })
        }
      })
    },
    createMatch1() {
      const {sportId, beginTime, tournamentId, matchStatus, awayTeamId, homeTeamId,phase,neutralGround,matchFormat } = this.dialogForm
      if(!sportId) {
        return this.$message.error('Please Select sport')
      }
      if(!beginTime) {
        return this.$message.error('Please Select Date')
      }
      if(!tournamentId) {
        return this.$message.error('Please Select tournament')
      }
      if(!homeTeamId) {
        return this.$message.error('Please Select home')
      }
      if(!awayTeamId) {
        return this.$message.error('Please Select away')
      }
      if(!matchStatus || matchStatus === 0) {
        return this.$message.error('Please Select match status')
      }
      this.dialogForm.standardTournamentId = tournamentId
      validateStandardMatch(this.dialogForm).then(res => {
        if(res.code === 0) {
          if(!res.data) {
          createStandardMatch(this.dialogForm).then(res => {
              if(res.code === 0) {
                this.$message.success('Success')
                this.creatMatchDialog = false
                this.getList()
              }
            })
          } else {
            this.$message.error('The match already exists')
          }
        }
      })
    },
    createMatch() {
      if(!this.isOutright) {
        return this.createMatch1()
      }
      const {sportId, beginTime, endTime, tournamentId, year} = this.dialogForm
      if(!sportId) {
        return this.$message.error('Please Select sport')
      }
      if(!beginTime) {
        return this.$message.error('Please Select start time')
      }
      if(!endTime) {
        return this.$message.error('Please Select end time')
      }
      if(!tournamentId) {
        return this.$message.error('Please Select tournament')
      }
      validateOutrightMatch({sportId, tournamentId, year}).then(res => {
        if(res.code === 0) {
          if(!res.data) {
            createOutrightMatch(this.dialogForm).then(res => {
              if(res.code === 0) {
                this.$message.success('Success')
                this.creatMatchDialog = false
                this.getList()
              }
            })
          } else {
            // this.$message.error(`${res.data}`)
            this.$confirm(`${res.data} match already exists. Do you want to create it?`, '', { type: 'warning' }).then(res => {
              if(res === 'confirm') {
                 createOutrightMatch(this.dialogForm).then(res => {
                  if(res.code === 0) {
                    this.$message.success('Success')
                    this.creatMatchDialog = false
                    this.getList()
                  }
                })
              }
            })
          }
        }
      })
    },
    changeVideo(row, $event) {
      updateVideoStatus(row.id, $event).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
          }
        })
        .finally((e) => {
          this.getList()
        })
    },
    changeAnimation(row, $event) {
      updateAnimateStatus(row.id, $event).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
          }
        })
        .finally((e) => {
          this.getList()
        })
    },
    changePlayerNumberStatus(row, $event) {
      updatePlayerNumberStatus(row.id, $event).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
          }
        })
        .finally((e) => {
          this.getList()
        })
    },
    changeRepushStatus(row) {
      matchRePush(row.id).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
          }
        })
    },
    changeReverse(row, e, prow) {
      // this.$confirm('Are you sure change match reverse status?').then((res) => {
      updateReverse({
        thirdMatchId: row.id,
        matchType: this.matchType,
        reverse: e
      })
        .then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
          }
        })
        .finally((e) => {
          this.getList()
          this.expandChange(prow)
        })
      // }).catch(e => {
      //   row.isReverse = e ? 0: 1
      // })
    },
    marketMappingFn(row) {
      const { href } = this.$router.resolve({
        path: '/market-mapping',
        query: { matchId: row.sourceMatchId, info: JSON.stringify(row) }
      })
      window.open(href, '_blank')
    },
    updataStatus() {
      let result = this.functionInfoList.find((item) => {
        return (
          item.privilegeMark === 'TMC/Page/match/matchLangaugeEdit' &&
          item.featureStatus !== 3
        )
      })
      if (!result)
        return this.$message({
          message: 'You do not have permission!',
          type: 'error'
        })
      this.langStatus = true
    },
    updateLanData() {
      let array = [
        'sport',
        'region',
        'tournament',
        'homeTeam',
        'awayTeam',
        'match'
      ]
      array.forEach((e) => {
        this.updataLang(e)
      })
    },
    updataLang(type) {
      let data = {}
      let params = {}
      data = this.cloneLangData[type]
      if (!data) {
        return
      }
      let stats = false
      this.langDialogData.data.forEach((e) => {
        if (data && data.language[e.lang] !== e[type]) {
          data.language[e.lang] = e[type]
          stats = true
        }
      })
      params = {
        id: data.id,
        json: JSON.stringify(data.language),
        language: data.language,
        nameCode: data.nameCode,
        remark: data.remark
      }
      if (type === 'sport') params.libraryType = '1'
      if (type === 'region') params.libraryType = '2'
      if (type === 'tournament') params.libraryType = '3'
      if (type === 'homeTeam' || type === 'awayTeam') params.libraryType = '4'
      if (type === 'match') params.libraryType = '16'
      this.langStatus = false
      this.langDialogData.show = false
      if (!stats) return
      editData(params).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: 'success',
            type: 'success'
          })
        }
      })
    },
    thirdCompared(standard, thirdData, dataSourceCode) {
      if (
        String(standard).replace(/(^\s*)|(\s*$)/g, '') !==
        String(thirdData).replace(/(^\s*)|(\s*$)/g, '')
      ) {
        if (originMapping[dataSourceCode] === 'SR') {
          return 'color:red'
        } else {
          return 'color:#409EFF'
        }
      }
    },
    changePage(e) {
      if (this.dateRange) {
        this.queryData.current = e
        this.getList()
      } else {
        this.$message.error('Must select a time range')
        this.list = []
        this.total = 0
      }
    },
    eidtRegion(row, index) {
      editMatchRegion({ matchId: row.id, regionId: row.regionId }).then(
        (res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
          }
        }
      )
      setTimeout(() => {
        this.search()
      }, 1000)
    },
    eidtMatchStatus(row, index) {
      this.$msgbox({
        title: '',
        type: 'warning',
        message: `Change Match Status to ${
          this.matchStatusList[row.matchStatus].label
        }`,
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel'
      })
        .then((action) => {
          changMatchStatus({
            matchId: row.id,
            matchStatus: row.matchStatus
          }).then((res) => {
            if (res.code === 0) {
              this.$message({
                message: 'success',
                type: 'success'
              })
              this.getList()
            }
          })
        })
        .catch((action) => {
          this.matchStatusList.forEach((e) => {
            if (e.label === row.matchStatusText) {
              this.list[index].matchStatus = e.value
            }
          })
        })
    },
    changeIgnore(row, e) {
      editTime({ matchId: row.id, matchType: this.matchType, ignore: e }).then(
        (res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
            this.getList()
          }
        }
      )
    },
    editFormat(row) {
      editTime({
        matchId: row.id,
        matchType: this.matchType,
        matchFormat: row.matchFormat
      }).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: 'success',
            type: 'success'
          })
          this.getList()
        }
      })
    },
    eidtPhase(row, index) {
      const op = this.phaseOptions.find(item => item.value === row.phase) || {}
      this.$msgbox({
        title: '',
        type: 'warning',
        message: `Change Phase to ${op.label}`,
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel'
      })
        .then((action) => {
          editTime({
            matchId: row.id,
            matchType: this.matchType,
            phase: row.phase
          }).then((res) => {
            if (res.code === 0) {
              this.$message({
                message: 'success',
                type: 'success'
              })
              this.getList()
            }
          })
        })
        .catch((action) => {
          this.getList()
        })
    },
    updateTime(row) {
      this.$msgbox({
        title: 'Modify the Time',
        type: 'warning',
        message: `${
          this.isOutright ? 'End Time' : 'Start'
        } time changed to ${parseTime(
          this.isOutright ? row.endTime : row.beginTime,
          '{y}-{m}-{d} {h}:{i}:{s}'
        )}`,
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel'
      }).then((action) => {
        const params = { matchId: row.id, matchType: this.matchType }
        if (this.isOutright) {
          params.endTime = new Date(row.endTime).getTime()
        } else {
          params.beginTime = new Date(row.beginTime).getTime()
        }
        editTime(params).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
            this.getList()
          }
        })
      })
    },
    remove(row, propsRow) {
      let index = this.list.findIndex((item) => item.id === propsRow.id)
      if (this.list[index].switchStatus && row.switchStatus) {
        this.$message({
          message: 'Push data has been pushed, unable to close',
          type: 'warning'
        })
        return
      }
      this.$msgbox({
        title: 'Delete Mapped',
        type: 'warning',
        message: `Do you want to unmap ?`,
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel'
      }).then((action) => {
        deleteMatch({ thirdMatchId: row.id }).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
            this.getList()
            this.expandChange(propsRow)
          }
        })
      })
    },
    hotBlur(event, data) {
      let value = event.target.value
      const { id } = data
      let p = /^([1-7][0-9][0-9][0-9])*$/
      if (
        value === '' ||
        value === '0000' ||
        (p.test(value) && value !== '1000')
      ) {
        if (value === '') {
          value = '0000'
        }
        let target = this.list.find((item) => item.id === id)
        if (target.hot !== value) {
          editMatchHot(id, value).then((res) => {
            let index = this.list.findIndex((item) => item.id === id)
            this.list.splice(index, 1, {
              ...this.list[index],
              hot: value
            })
            if (this.editHotData.id === id) {
              this.editHotData = {
                id: '',
                hot: ''
              }
            }
          })
        } else {
          if (this.editHotData.id === id) {
            this.editHotData = {
              id: '',
              hot: ''
            }
          }
        }
      }
    },
    editHotIdFunc(data) {
      this.editHotData = {
        id: data.id,
        hot: data.hot
      }
      this.$nextTick(() => {
        this.$refs.hotInput && this.$refs.hotInput.focus()
      })
    },

    showLangaugeDetail(row, btnStatus = false) {
      this.dialogTableVisible = false
      const editMatch = true
      this.langDialogData = {
        ...this.langDialogData,
        show: true,
        loading: true,
        data: [],
        btnStatus: btnStatus,
        editMatch
      }
      this.langStatus = false
      this.getLangData(row.id)
        .then((data) => {
          this.langDialogData = {
            ...this.langDialogData,
            loading: false,
            data: data.lang,
            editMatch
          }
        })
        .catch(() => {
          this.langDialogData = {
            ...this.langDialogData,
            show: true,
            loading: false,
            data: [],
            btnStatus: false,
            editMatch
          }
        })
    },
    cellEnterHover(data) {
      const { id, langData, loading = false } = data
      if (!langData && !loading) {
        this.timerGroup[id] = setTimeout(() => {
          this.getLangData(id)
        }, 1000)
      }
    },
    getLangData(id) {
      const target = this.list.find((item) => item.id === id)
      return new Promise((resolve, reject) => {
        const targetIndex = this.list.findIndex((item) => item.id === id)
        this.list.splice(
          targetIndex,
          1,
          Object.assign({}, this.list[targetIndex], {
            loading: true
          })
        )
        getMatchLanguage(id)
          .then((res) => {
            const { data } = res
            this.cloneLangData = data
            // console.log(data)
            const row = [
              'Sport',
              'Region',
              'Tournament',
              'HomeTeam',
              'AwayTeam'
            ]
            const lengs = data.sport.language
            const column = langList.filter((item) => item in lengs)
            let langData = column.map((item) => {
              return {
                lang: item,
                sport:
                  Array.isArray(data.sport) && data.sport.length > 0
                    ? data.sport[0].language[item]
                    : data.sport && data.sport.language[item],
                region:
                  Array.isArray(data.region) && data.region.length > 0
                    ? data.region[0].language[item]
                    : data.region && data.region.language[item],
                tournament:
                  Array.isArray(data.tournament) && data.tournament.length > 0
                    ? data.tournament[0].language[item]
                    : data.tournament && data.tournament.language[item],
                homeTeam:
                  Array.isArray(data.homeTeam) && data.homeTeam.length > 0
                    ? data.homeTeam[0].language[item]
                    : data.homeTeam && data.homeTeam.language[item],
                awayTeam:
                  Array.isArray(data.awayTeam) && data.awayTeam.length > 0
                    ? data.awayTeam[0].language[item]
                    : data.awayTeam && data.awayTeam.language[item],
                match:
                  Array.isArray(data.match) && data.match.length > 0
                    ? data.match[0].language[item]
                    : data.match && data.match.language[item],
                awayTeamLogo: data.awayTeamLogo,
                homeTeamLogo: data.homeTeamLogo,
                regionLogo: data.regionLogo,
                tournamentLogo: data.tournamentLogo
              }
            })
            const judgeArr = [].concat(
              ...langData.slice(0, 1).map((item) => Object.values(item))
            )
            let oppositeStatus = judgeArr.some((item) => item === '')

            this.list.splice(
              targetIndex,
              1,
              Object.assign({}, this.list[targetIndex], {
                loading: false,
                langData: {
                  lang: langData,
                  status: oppositeStatus ? 'error' : 'success'
                }
              })
            )

            resolve({
              lang: langData,
              status: oppositeStatus ? 'error' : 'success'
            })
          })
          .catch(() => {
            this.list.splice(
              targetIndex,
              1,
              Object.assign({}, this.list[targetIndex], {
                loading: false
              })
            )
            reject()
          })
      })
    },
    cellLeaveHover(data) {
      if (this.timerGroup[data.id]) {
        clearTimeout(this.timerGroup[data.id])
      }
    },
    parseTime,
    getData,
    expandChange(row) {
      let { id, dataSourceCode } = row
      if (dataSourceCode === 'null') dataSourceCode = null
      this.upDataExpandData(id, {
        loading: true,
        data: null
      })
      getSubMarches(id)
        .then((res) => {
          let list = res.data
          list = list.map((item) => ({
            ...item,
            switchStatus: item.dataStatus === 1
          }))
          this.upDataExpandData(id, {
            loading: false,
            data: list
          })
        })
        .catch(() => {
          this.upDataExpandData(id, {
            loading: false,
            data: null
          })
        })
    },
    upDataExpandData(id, data) {
      let expandData = cloneDeep(this.expandData)
      expandData[id] = data
      this.expandData = expandData
    },
    getList() {
      this.loading = true
      const extraData = {...this.fliterParams(this.extraData, ['matchId'])} || {}
      this.getData({
        ...this.queryData,
        ...extraData,
        matchType: this.matchType
      })
        .then((res) => {
          const data = res.data
          if (res.code === 0) {
            let list = data.records
            list = list.map((item) => ({
              ...item,
              matchStatusText: matchStatusMapping[item.matchStatus],
              switchStatus: item.dataStatus === 1,
              neutralGround: item.neutralGround === 1,
              dataStatusText: dataStatusMapping[item.dataStatus]
            }))

            this.list = list
            this.total = data.total
            this.loading = false
          }
        })
        .catch((err) => {
          this.list = []
          this.loading = false
        })
    },
    thirdPushData(row, props) {
      if (row.switchStatus) {
        let result = this.functionInfoList.find((item) => {
          return (
            item.privilegeMark === 'TMC/Page/match/matchClose' &&
            item.featureStatus !== 3
          )
        })
        if (!result)
          return this.$message({
            message: 'You do not have permission!',
            type: 'error'
          })
        this.thirdSwitchDataStatus(row, props)
      } else {
        let result = this.functionInfoList.find((item) => {
          return (
            item.privilegeMark === 'TMC/Page/match/matchOpen' &&
            item.featureStatus !== 3
          )
        })
        if (!result)
          return this.$message({
            message: 'You do not have permission!',
            type: 'error'
          })
        this.thirdSwitchDataStatus(row, props)
      }
    },
    thirdSwitchDataStatus(row, props) {
      const newDataStatus = row.switchStatus ? 'Error/Disable' : 'Normal'
      console.log(row)
      let text = ''
      if(![0,4,5].includes(+row.matchStatus)) {
        let status = ''
        matchStatusList.map(item => {
          if(+item.value === +row.matchStatus) {
            status = item.label
          }
        })
        text = `${originMapping[row.dataSourceCode]} match status is ${status}`
      }
      this.$msgbox({
        title: 'Change status',
        type: 'warning',
        message: `${
          row.switchStatus ? 'Choose match status is Error/Disable' : text? text + ', push this match' : 'Push this match'
        }?`,
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel'
      }).then((action) => {
        thirdDataStatus({
          thirdMatchId: row.id,
          dataStatus: row.switchStatus ? 0 : 1
        }).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'Successful operation',
              type: 'success'
            })
            this.expandChange(props)
          }
        })
      })
    },
    switchHandle(row) {
      this.gridData = []
      if (row.switchStatus) {
        let result = this.functionInfoList.find((item) => {
          return (
            item.privilegeMark === 'TMC/Page/match/matchClose' &&
            item.featureStatus !== 3
          )
        })
        if (!result)
          return this.$message({
            message: 'You do not have permission!',
            type: 'error'
          })
        this.switchDataStatus(row)
      } else {
        let result = this.functionInfoList.find((item) => {
          return (
            item.privilegeMark === 'TMC/Page/match/matchOpen' &&
            item.featureStatus !== 3
          )
        })
        if (!result)
          return this.$message({
            message: 'You do not have permission!',
            type: 'error'
          })
        this.gridData.push(row)
        this.dialogTableVisible = true
        getSubMarches(row.id).then((res) => {
          if (res.code === 0) {
            if (!res.data || res.data.length === 0) return
            res.data.forEach((e) => {
              this.gridData.push(e)
            })
          }
        })
      }
    },
    openPushData() {
      this.getLangData(this.gridData[0].id).then((data) => {
        if (data.status === 'success' || this.isOutright) {
          this.switchDataStatus(this.gridData[0])
        } else {
          this.$message({
            message: 'The language text is incomplete!',
            type: 'error'
          })
        }
      })
    },
    openLangaugeDetail() {
      this.showLangaugeDetail(this.gridData[0], true)
    },
    switchNeutralGround(row) {
      this.$msgbox({
        title: 'Change status',
        type: 'warning',
        message: `The match field is neutral`,
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel'
      }).then((action) => {
        updateNeutralGround({
          matchId: row.id,
          neutralGround: row.neutralGround ? 0 : 1
        }).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
            this.getList()
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      })
    },
    switchDataStatus(row) {
      const newDataStatus = row.switchStatus ? 'Error/Disable' : 'Normal'
      console.log(row)
      let list = []
      this.gridData.map(item => {
        if(item.dataSourceCode && ![0,4,5].includes(+item.matchStatus)) {
          list.push(item)
        }
      })
      if(list.length) {
        const statsMap = {}
        matchStatusList.map(item => {
          statsMap[item.value] = item.label
        })
        list = list.map(item => {
          return `${originMapping[item.dataSourceCode]} match status is ${statsMap[item.matchStatus]}`
        })
      }
      this.$msgbox({
        title: 'Change status',
        type: 'warning',
        message: `${
          row.switchStatus ? 'Choose match status is  Error/Disable' : (list.length >0 ? list.join(',') + ', push this match' : 'Push this match') 
        }?`,
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel'
      }).then((action) => {
        editMatchStatus({
          matchId: row.id,
          dataStatus: row.switchStatus ? 0 : 1
        }).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'Successful operation',
              type: 'success'
            })
            this.expandChange(row)
            let index = this.list.findIndex((item) => item.id === row.id)
            this.list.splice(
              index,
              1,
              Object.assign({}, this.list[index], {
                dataStatusText: newDataStatus,
                switchStatus: row.switchStatus ? false : true
              })
            )
            this.dialogTableVisible = false
            this.langDialogData.show = false
          }
        })
      })
    },
    dateRangeFn(val, type) {
      if (this.extraData.matchQueryType) {
        this.extraData.matchQueryType = ''
      }
      if (!val) {
        this.extraData.startTime = ''
        this.extraData.endTime = ''
      } else {
        const [start, end] = val
        this.extraData.startTime = start
        this.extraData.endTime = end
      }
      if (type === 7) {
        this.extraData.startTime = ''
        this.extraData.endTime = +new Date()
        this.extraData.matchQueryType = 6
      }
      this.$nextTick(() => {
        this.search()
      })
    }
  }
}
</script>
